// TODO нужен обход полей таблицы
const createModelFromTemplate = (fields) => {
  const obj = {};
  fields.forEach((el) => {
    if (el.class === 'text') return;
    obj[el.fieldId] = el.value;
    if (['select', 'priority', 'radioGroup'].includes(el.class)) {
      obj[el.fieldId] = el.value ? el.value.id : undefined;
    }
    if (el.class === 'checkboxGroup') {
      obj[el.fieldId] = el.values ? el.values.map((c) => c.id) : undefined;
    }
    // обход групп
    if (el.childs && el.childs.length) {
      obj[el.id] = createModelFromTemplate(el.childs);
    }
  });
  return obj;
};

export default createModelFromTemplate;
