import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';

import { TYPES, SOURCE_SYSTEM } from '@/constants/network';

const getDate = (field) => {
  const format = field.showTime ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY';

  if (field.viewMode === 'single') {
    return dayjs(field.value, format).toDate();
  }
  if ((field.viewMode === 'range') || (field.viewMode === 'multiple')) {
    const dates = field.value.split(' - ');
    return dates.map((d) => dayjs(d, format).toISOString());
  }

  return null;
};

const getNetwork = (field) => {
  if (field.value) {
    const value = {
      sourceSystem: SOURCE_SYSTEM,
    };
    // eslint-disable-next-line no-confusing-arrow
    let type = TYPES.find((t) => t.separator ? field.value.includes(t.separator) : (t.label === field.value));
    if (isEmpty(type)) {
      // Хост
      [type] = TYPES;
      value.type = type.type;
      value.ipAddress = field.value;
    } else {
      // Для Интернета этого хватит
      value.type = type.type;

      if (type.type !== 'internet') {
        const arr = field.value.split(type.separator);
        // Сеть
        if (type.type === 'network') {
          [value.ipAddress, value.netMask] = arr;
        } else { // Диапазон
          [value.startIpAddress, value.endIpAddress] = arr;
        }
      }
    }
    return value;
  }

  return null;
};

const getEmployeeReplacement = (field) => {
  if (field.value) {
    const value = {};
    if (field.showEmployee) {
      value.employeeLogin = null;
    }
    const addressArr = field.value.split(', ');
    /* eslint-disable prefer-destructuring */
    value.region = addressArr[0];
    value.room = addressArr[(addressArr.length - 1)];
    value.floor = addressArr[(addressArr.length - 2)];
    value.address = addressArr.slice(1, -2).join(', ');
    /* eslint-enable prefer-destructuring */
    return value;
  }

  return null;
};

const createModelFromTicket = (fields) => {
  const model = {};
  fields.forEach((field) => {
    if (isEmpty(field.value) && isEmpty(field.valueId) && isEmpty(field.searchResults)) return;

    switch (field.class) {
    case 'input':
    case 'calendar':
    case 'contactInfo':
      model[field.id] = field.value;
      break;
    case 'textarea':
      model[field.id] = field.value.replace(/<br\/>/g, '\n');
      break;
    case 'checkboxGroup':
      model[field.id] = field.itemsValues?.map((v) => v.id);
      break;
    case 'radioGroup':
      model[field.id] = field.itemsValues?.find((v) => v.value)?.id;
      break;
    case 'select':
    case 'priority':
      model[field.id] = field.valueId || undefined;
      break;
    case 'graphCalendar':
      model[field.id] = getDate(field);
      break;
    case 'cmdb':
      model[field.id] = Object.values(field.cmdbItemInfoMap)?.find((v) => v.text === field.value)?.id || null;
      break;
    case 'counter':
      model[field.id] = Number(field.value);
      break;
    case 'group':
      model[field.id] = createModelFromTicket(field.childs);
      break;
    case 'search':
      model[field.id] = field.searchResults || [];
      break;
    // case 'resourceField':
    //   model[field.id] = getResource(field);
    //   break;
    case 'network':
      model[field.id] = getNetwork(field);
      break;
    case 'employeeReplacement':
      model[field.id] = getEmployeeReplacement(field);
      break;
    default:
      break;
    }
  });
  return model;
};

export default createModelFromTicket;
