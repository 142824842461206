<template>
  <div class="surveys-detail-inner">
    <template v-if="survey">
      <div class="surveys-detail-inner__main">
        <div class="surveys-detail-inner__toolbar">
          <div class="surveys-detail-inner__info">
            <div class="surveys-detail-inner__label typo-body-m">
              {{ survey.id }}
            </div>
            <div class="surveys-detail-inner__label typo-body-m">
              <base-date
                :date="survey.startDatetime"
                :show-time="false"
                show-year
              />
            </div>
            <esmp-tag
              :color="getStatus(survey.state).color"
              :is-status="true"
              class="typo-caption"
            >
              {{ getStatus(survey.state).name }}
            </esmp-tag>
          </div>
          <div class="surveys-detail-inner__actions">
            <!-- TODO: будет реализовано позже -->
            <!-- <esmp-button
              view="outline"
              size="small"
              class="typo-caption"
            >
              Удалить
            </esmp-button>
            <esmp-button
              v-if="isEditBtnShown"
              view="outline"
              size="small"
              class="typo-caption"
            >
              Редактировать
            </esmp-button> -->
          </div>
        </div>
        <div class="surveys-detail-inner__title">
          {{ survey.title }}
        </div>
        <div class="surveys-detail-inner__description typo-body-m">
          {{ survey.description }}
        </div>
        <div class="surveys-detail-inner__subtitle typo-accent-m">
          Список вопросов:
        </div>
        <div
          :class="[
            'surveys-detail-inner__list-box',
            {
              'surveys-detail-inner__list-box--disabled': !isSurveyEnable,
            }
          ]"
        >
          <esmp-loader v-if="!checklist" />
          <hm-form
            v-else
            ref="form"
            :fields="checklist"
            v-model="model"
          />
          <!--
          <div
            class="surveys-detail-inner__question typo-body-m"
            v-for="question in questions"
            :key="question.id"
          >
            <div class="surveys-detail-inner__question-number">
              Вопрос {{ question.id }}:
            </div>
            <div class="surveys-detail-inner__question-text">
              {{ question.text }}
            </div>
            <div class="surveys-detail-inner__question-label">
              Ответ:
            </div>
            <div class="surveys-detail-inner__question-answer">
              {{ question.answer }}
            </div>
          </div>
          -->
        </div>
      </div>
      <div class="surveys-detail-inner__sidebar">
        <div
          v-if="infoBlock"
          class="surveys-detail-inner__block"
        >
          <surveys-info-block :info-block="infoBlock" />
        </div>
        <div
          v-if="attachmentList && attachmentList.length"
          class="surveys-detail-inner__block"
        >
          <h3 class="surveys-detail-inner__title typo-accent-m">
            Вложения
          </h3>
          <p>Список вложений пуст</p>
          <!--
          <attachment-list :attachment-list="attachmentList" />
          -->
        </div>
      </div>
      <div
        v-if="isSurveyEnable"
        :class="['surveys-detail-inner__footer', { 'surveys-detail-inner__footer--wide': isCollapsedMenu}]"
      >
        <esmp-button
          class="surveys-detail__action"
          view="outline"
          @click="saveSurvey('save')"
        >
          Промежуточное сохранение
        </esmp-button>
        <esmp-button
          class="surveys-detail__action"
          view="outline"
          @click="saveSurvey('finish')"
        >
          Закончить опрос
        </esmp-button>
        <!--
        <esmp-button
          class="surveys-detail__action"
          view="outline"
        >
          Проверить опрос
        </esmp-button>
        -->
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { getStatus } from '@/helpers/status';
// import AttachmentList from '@/pages/Ticket/components/AttachmentList.vue';
import BaseDate from '@/components/base/base-date';
import HmForm from '@/components/hm-form/';
import getValidChecklistFields from '@/components/hm-form/helpers/getValidChecklistFields';
import createModelFromTicket from '@/components/hm-form/helpers/createModelFromTicket';
import createModelFromTemplate from '@/components/hm-form/helpers/createModelFromTemplate';
import mappedFieldsForSurvey from '@/components/hm-form/helpers/mappedFieldsForSurvey';
import SurveysInfoBlock from './InfoBlock.vue';

export default {
  name: 'SurveyDetail',
  components: {
    // AttachmentList,
    SurveysInfoBlock,
    BaseDate,
    HmForm,
  },
  data() {
    return {
      questions: [
        {
          id: 1,
          text: 'Проводится ли регулярное обучение?',
          answer: 'да, проводится, но проводится не полностью, хотелось бы, чтобы проверки проходили чаще',
        },
        {
          id: 2,
          text: 'Проводится ли регулярное обучение?',
          answer: 'да, проводится, но проводится не полностью, хотелось бы, чтобы проверки проходили чаще',
        },
        {
          id: 3,
          text: 'Проводится ли регулярное обучение?',
          answer: 'да, проводится, но проводится не полностью, хотелось бы, чтобы проверки проходили чаще',
        },
        {
          id: 4,
          text: 'Проводится ли регулярное обучение?',
          answer: 'да, проводится, но проводится не полностью, хотелось бы, чтобы проверки проходили чаще',
        },
      ], // TODO: тестовые данные, пока нет бека
      attachmentList: [
        {
          name: 'Пояснительная_записка_к_приказу_о_вводе_в_эксплуатацию_доработок123.docx',
          // eslint-disable-next-line max-len
          url: 'http://localhost:8080/api/tickets/-/249922/attachments/1363815/2?source=otrs1&attachmentName=%D0%9F%D0%BE%D1%8F%D1%81%D0%BD%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F_%D0%B7%D0%B0%D0%BF%D0%B8%D1%81%D0%BA%D0%B0_%D0%BA_%D0%BF%D1%80%D0%B8%D0%BA%D0%B0%D0%B7%D1%83_%D0%BE_%D0%B2%D0%B2%D0%BE%D0%B4%D0%B5_%D0%B2_%D1%8D%D0%BA%D1%81%D0%BF%D0%BB%D1%83%D0%B0%D1%82%D0%B0%D1%86%D0%B8%D1%8E_%D0%B4%D0%BE%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BE%D0%BA.docx',
          size: 19631,
          format: 'document',
          extension: 'docx',
          date: '2023-04-28 10:01:47+0000',
        },
        {
          name: 'Пояснительная_записка_к_приказу_о_вводе_в_эксплуатацию_доработок.docx',
          // eslint-disable-next-line max-len
          url: 'http://localhost:8080/api/tickets/-/249922/attachments/1363815/2?source=otrs1&attachmentName=%D0%9F%D0%BE%D1%8F%D1%81%D0%BD%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F_%D0%B7%D0%B0%D0%BF%D0%B8%D1%81%D0%BA%D0%B0_%D0%BA_%D0%BF%D1%80%D0%B8%D0%BA%D0%B0%D0%B7%D1%83_%D0%BE_%D0%B2%D0%B2%D0%BE%D0%B4%D0%B5_%D0%B2_%D1%8D%D0%BA%D1%81%D0%BF%D0%BB%D1%83%D0%B0%D1%82%D0%B0%D1%86%D0%B8%D1%8E_%D0%B4%D0%BE%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BE%D0%BA.docx',
          size: 19631,
          format: 'document',
          extension: 'docx',
          date: '2023-04-28 10:01:47+0000',
        },
        {
          name: 'Пояснительная_записка_к_приказу_о_вводе_в_эксплуатацию_доработок.docx',
          // eslint-disable-next-line max-len
          url: 'http://localhost:8080/api/tickets/-/249922/attachments/1363815/2?source=otrs1&attachmentName=%D0%9F%D0%BE%D1%8F%D1%81%D0%BD%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F_%D0%B7%D0%B0%D0%BF%D0%B8%D1%81%D0%BA%D0%B0_%D0%BA_%D0%BF%D1%80%D0%B8%D0%BA%D0%B0%D0%B7%D1%83_%D0%BE_%D0%B2%D0%B2%D0%BE%D0%B4%D0%B5_%D0%B2_%D1%8D%D0%BA%D1%81%D0%BF%D0%BB%D1%83%D0%B0%D1%82%D0%B0%D1%86%D0%B8%D1%8E_%D0%B4%D0%BE%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BE%D0%BA.docx',
          size: 19631,
          format: 'document',
          extension: 'docx',
          date: '2023-04-28 10:01:47+0000',
        },
      ], // TODO: тестовые данные, пока нет бека
      infoBlock: null, // TODO: тестовые данные, пока нет бека
      survey: null,
      checklist: null,
      model: null,
      formVersionId: null,
    };
  },
  computed: {
    ...mapState('sidebar', ['isCollapsedMenu']),
    surveyState() {
      return this.survey?.state?.name;
    },
    isSurveyEnable() {
      return this.surveyState
        && (this.surveyState === 'new' || this.surveyState === 'inProgress');
    },
    isEditBtnShown() {
      return !(!this.surveyState
        || this.surveyState === 'new'
        || this.surveyState === 'finished'
        || this.surveyState === 'checked');
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    getStatus,
    async getSurveyChecklist() {
      this.setLoading({ key: 'page', value: true });

      const { id } = this.$route.params;
      let templateId;

      await this.$API.survey.getSurveyChecklist(id)
        .then(({ data }) => {
          this.survey = data;
          this.infoBlock = {
            priority: this.survey.priority,
            startDatetime: this.survey.startDatetime,
            finishDatetime: this.survey.finishDatetime,
            creator: this.survey.creator,
            executor: this.survey.executor,
          };
          this.formVersionId = data.formVersionId;
          templateId = data.templateId || null;
          this.checklist = Object.freeze(getValidChecklistFields(data.fields));
        });

      if (this.surveyState === 'inProgress' || this.surveyState === 'finished') {
        await this.$API.survey.getSurveyChecklistTemplate(templateId)
          .then(({ data }) => {
            this.model = createModelFromTemplate(data.items);
          });
      } else {
        this.model = createModelFromTicket(this.checklist);
      }

      this.setLoading({ key: 'page', value: false });

      this.$router.setMetaOption(this.$route, 'title', this.survey.title);
    },
    async saveSurvey(method) {
      this.setLoading({ key: 'page', value: true });

      const noErros = await this.$refs.form.validate();
      if (!noErros) {
        this.$EsmpNotify.$show('Поля опроса не корректно заполнены', 'error');
        return;
      }

      const { surveyId } = this.$route.params;
      const isFinish = method === 'finish';

      const data = {
        id: Number(surveyId),
        name: this.survey.title,
        formVersionId: Number(this.formVersionId),
        sourceSystem: 'otrs1',
        items: mappedFieldsForSurvey(this.model, this.checklist),
      };
      this.$API.survey[method](data).then(() => {
        const text = isFinish ? 'Отправлено на проверку' : 'Опрос сохранен';
        this.$EsmpNotify.$show(text, 'success');

        if (isFinish) {
          this.$nextTick(() => {
            this.$router.push({
              name: 'SurveysList',
            });
          });
        }
      }).finally(() => {
        this.setLoading({ key: 'page', value: false });
      });
    },
  },
  created() {
    this.getSurveyChecklist();
  },
};
</script>

<style lang="scss">
.surveys-detail {
  &__actions {
    display: flex;
    align-items: center;
    gap: $base-gutter;
  }
  &__action {
    font-weight: 500;
    &--function {
      color: $color-black;
    }
  }
}

.surveys-detail-table {
  &__head {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    gap: $base-gutter / 1.5;
    padding: 0px 27px 8px;
  }
  &__head-item {
    color: $color-black-op-50;
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: $base-gutter / 1.5;
  }
  &__actions {
    text-align: center;
  }
  &__item {
    position: relative;

    &:hover {
      box-shadow: 0px 0px 20px rgba(28, 41, 61, 0.05), 0px 12px 20px rgba(28, 41, 61, 0.06);
      transition: box-shadow $base-animation;
    }
  }
  &__item-inner {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    align-items: center;
    position: relative;
    z-index: 2;
    padding: 10px 27px;
    background-color: $color-white;
    border-radius: $base-border-radius;
    font-weight: 500;
  }
  &__hat {
    position: absolute;
    top: 0;
    left: -8px;
    height: 32px;
    width: 24px;
    border-radius: $base-border-radius;
    z-index: 1;
    opacity: .25;
  }
  &__theme {
    position: relative;
    span {
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  }
  &__priority {
    position: absolute;
    top: 2px;
    left: -16px;
    .esmp-svg-icon {
      width: 12px;
      height: 12px;
    }
  }

  &__action {
    color: $color-black;
    font-weight: 500;
  }

  &__user {
    display: flex;
    align-items: center;
    gap: $base-gutter / 3;

    .esmp-user-avatar {
      flex-shrink: 0;
    }
  }
}

.surveys-detail-inner {
  display: flex;
  gap: $gap;
  padding-bottom: 80px;
  &__main {
    background-color: $color-white;
    width: 70%;
    border-radius: $base-border-radius * 2;
    padding: 32px;
  }
  &__sidebar {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: $gap;
  }
  &__footer {
    display: flex;
    align-items: center;
    gap: $base-gutter;
    width: calc(100% - #{$sidebar-width});
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: $color-white;
    padding: $gap / 2 $gap;
    &--wide {
      width: calc(100% - #{$sidebar-collapse-width});
    }
  }
  &__toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $base-gutter;
  }
  &__info {
    display: flex;
    align-items: center;
    gap: $base-gutter / 1.5;
    font-weight: 500;
  }
  &__label {
    color: $color-grayscale-40;

    &:not(:last-child) {
      padding-right: $base-gutter / 1.5;
      position: relative;

      &::after {
        content: '';
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: $color-grayscale-40;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -1px;
      }
    }
  }
  &__actions {
    display: flex;
    gap: $base-gutter / 1.5;

    .esmp-button {
      border-color: $color-grayscale-10;
      height: 24px;
      font-weight: 500;
    }
  }
  &__title {
    font-weight: 700;
    font-size: 22px;
    line-height: 24px;
    margin-bottom: $base-gutter;
    word-break: break-word;
  }
  &__subtitle {
    font-weight: 700;
    margin-bottom: $base-gutter * 2;
  }
  &__list-box {
    border: 1px solid $color-grayscale-10;
    border-radius: $base-border-radius;
    padding: $base-gutter * 2;
    &--disabled {
      pointer-events: none;
      opacity: 0.7;
    }
  }
  &__description {
    color: $color-black-op-70;
    margin-bottom: $base-gutter * 2;
    word-break: break-word;
  }
  &__results {
    display: flex;
  }
  &__result {
    display: flex;
    align-items: center;
    margin-right: $base-gutter * 2;
    font-weight: 700;

    .esmp-svg-icon {
      width: 15px;
      margin-left: $base-gutter / 3;
    }
  }
  &__rate {
    .esmp-svg-icon {
      width: 24px;
    }
    &--up {
      .esmp-svg-icon {
        color: $color-green-status;
      }
    }
    &--down {
      .esmp-svg-icon {
        color: $color-red;
      }
    }
  }
  &__history {
    color: $color-black;

    .esmp-svg-icon {
      width: 15px;
    }
  }
  &__box {
    padding: 8px;
    border-radius: $base-border-radius * 1.5;
    border: 1px solid $color-grayscale-10;
  }
  &__common {
    padding: 12px 16px;
    border-radius: $base-border-radius * 1.5;
    background-color: $color-grayscale-05;
    font-weight: 500;
  }
  &__item {
    display: flex;
    &:not(:last-child) {
      margin-bottom: $base-gutter / 1.4;
    }
  }
  &__col {
    &--wide {
      width: 100%;
    }
    &--small {
      width: 8%;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      gap: $base-gutter;

      .esmp-svg-icon {
        width: 15px;
      }
    }
  }
  &__name {
    color: $color-black-op-50;
  }
  &__list {
    padding: 12px 16px;
  }
  &__head {
    color: $color-black-op-50;
  }
  &__body {
    margin-bottom: $base-gutter * 1.4;
  }
  &__block {
    background-color: $color-white;
    border-radius: $base-border-radius * 2;
    padding: 16px;
  }
  &__title {
    font-weight: 700;
    margin-bottom: $base-gutter * 1.4;
  }
  &__question {
    padding: $base-gutter * 1.4 0;
    border-bottom: 1px solid $color-black-op-10;
  }
  &__question-number {
    color: $color-black-op-50;
  }
  &__question-text {
    margin-bottom: $base-gutter / 1.5;
  }
  &__question-label {
    color: $color-primary-1-day;
  }
  .base-date {
    font-size: 14px;
    line-height: 20px;
  }
}
</style>
