// TODO Шаблоны определенно нужно дорабатывать, не все контролы обрабатываются, формат возможно некорректный
// TODO нужен рекурсивный обход для групп и обход полей таблицы
const mappedFieldsForSurvey = (model, fields) => {
  const arr = [];

  Object.keys(model).forEach((id) => {
    const field = fields.find((el) => el.id === id);

    if (!field || ['uploader', 'text'].includes(field.class)) return;

    if (field.class === 'resourceField' && !model[id][0].resource) return;

    // Эти свойства есть всегда у всех типов контроллов
    const fieldModel = {
      fieldId: id,
      class: field.class,
      value: model[id],
    };

    if (field.class === 'checkboxGroup') {
      delete fieldModel.value;

      if (model[id]) {
        fieldModel.values = field.listValues.filter((el) => model[id].includes(el.id));
      }
    }

    if (field.class === 'counter') {
      fieldModel.value = String(model[field.id]);
    }

    if (field.class === 'radioGroup') {
      if (model[id]) {
        fieldModel.value = field.listValues.find((el) => model[id].includes(el.id));
      }
    }

    if (['select', 'priority'].includes(field.class)) {
      if (model[id]) {
        const value = field.listValues.find((el) => model[id].includes(el.id));

        delete value.connectedGroups;

        fieldModel.value = value;
      }
    }

    arr.push(fieldModel);
  });

  return arr;
};

export default mappedFieldsForSurvey;
