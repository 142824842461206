<template>
  <div class="surveys-info-block">
    <div v-if="infoBlock.priority" class="surveys-info-block__field">
      <div class="surveys-info-block__label typo-caption">
        Приоритет
      </div>
      <div class="surveys-info-block__value typo-caption">
        <div
          class="surveys-info-block__priority"
          :style="`background: ${infoBlock.priority.color}`"
        >
          {{ infoBlock.priority.translatedName }}
        </div>
      </div>
    </div>
    <div v-if="infoBlock.startDatetime" class="surveys-info-block__field">
      <div class="surveys-info-block__label typo-caption">
        Дата начала
      </div>
      <div class="surveys-info-block__value typo-caption">
        <base-date
          :date="infoBlock.startDatetime"
          :show-time="false"
          show-year
        />
      </div>
    </div>
    <div v-if="infoBlock.finishDatetime" class="surveys-info-block__field">
      <div class="surveys-info-block__label typo-caption">
        Исполнить до:
      </div>
      <div class="surveys-info-block__value typo-caption">
        <base-date
          :date="infoBlock.finishDatetime"
          :show-time="false"
          show-year
        />
      </div>
    </div>
    <div v-if="infoBlock.creator" class="surveys-info-block__field">
      <div class="surveys-info-block__label typo-caption">
        Создал
      </div>
      <div class="surveys-info-block__value typo-caption">
        <div class="surveys-info-block__user">
          <esmp-user-avatar
            :username="infoBlock.creator.fullName"
          />
          {{ infoBlock.creator.fullName }}
        </div>
      </div>
    </div>
    <div v-if="infoBlock.executor" class="surveys-info-block__field">
      <div class="surveys-info-block__label typo-caption">
        Исполнитель
      </div>
      <div class="surveys-info-block__value typo-caption">
        <div class="surveys-info-block__user">
          <esmp-user-avatar
            :username="infoBlock.executor.fullName"
          />
          {{ infoBlock.executor.fullName }}
        </div>
      </div>
    </div>
    <!-- TODO: будет реализовано позже -->
    <!-- <div v-if="infoBlock.auditor" class="surveys-info-block__field">
      <div class="surveys-info-block__label typo-caption">
        Аудитор
      </div>
      <div class="surveys-info-block__value typo-caption">
        <div class="surveys-info-block__user">
          <esmp-user-avatar
            :username="infoBlock.auditor.fullName"
          />
          {{ infoBlock.auditor.fullName }}
        </div>
      </div>
    </div>-->
  </div>
</template>

<script>
import BaseDate from '@/components/base/base-date';

export default {
  name: 'SurveysInfoBlock',
  components: {
    BaseDate,
  },
  props: {
    infoBlock: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.surveys-info-block {
  &__field {
    display: flex;
    align-items: center;
    gap: $base-gutter / 2;
    &:not(:last-child) {
      margin-bottom: $gap /2;
    }
  }
  &__label {
    width: 90px;
    flex-shrink: 0;
    color: $color-grayscale-60;
  }
  &__priority {
    border-radius: $base-border-radius;
    padding: 2px 8px;
    color: $color-white;
    font-weight: 500;
  }
  &__user {
    display: flex;
    align-items: center;
    gap: $base-gutter / 3;
  }
}
</style>
